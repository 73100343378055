import { event } from "nextjs-google-analytics";

export enum ANALYTICS_EVENT_NAMES {
  "BASKET_CHECKOUT" = "BASKET_CHECKOUT",
  "NEWSLETTER_POPUP_DISMISS" = "NEWSLETTER_POPUP_DISMISS",
  "PRODUCT_PAGE_ADD_TO_BAG" = "PRODUCT_PAGE_ADD_TO_BAG",
  "PRODUCT_PAGE_SELECT_VARIANT" = "PRODUCT_PAGE_SELECT_VARIANT",
  "PRODUCT_PAGE_SELECT_SIZE" = "PRODUCT_PAGE_SELECT_SIZE",
  "CHECKOUT_APPLY_DISCOUNT_CODE" = "CHECKOUT_APPLY_DISCOUNT_CODE",
  "CHECKOUT_COMPLETE" = "CHECKOUT_COMPLETE",
  "NAVIGATION_TOGGLE" = "NAVIGATION_TOGGLE",
}

export enum ANALYTICS_EVENT_CATEGORIES {
  "BASKET" = "BASKET",
  "CHECKOUT" = "CHECKOUT",
  "NEWSLETTER_POPUP" = "NEWSLETTER_POPUP",
  "PRODUCT_PAGE" = "PRODUCT_PAGE",
}

type ITrackEventProps = {
  name: ANALYTICS_EVENT_NAMES;
  category: ANALYTICS_EVENT_CATEGORIES;
};

export const trackEvent = (props: ITrackEventProps) => {
  const { name, category } = props;

  if (process.env.NODE_ENV === "production") {
    event(name, {
      category,
    });
  }
};
