import { createContext } from '@bitmap/utils/utils/createContext';
import { IFetchCurrencies, IFetchShippingRates } from '@bitmap/contracts';
import { IFetchShippingDestinations } from '@bitmap/contracts';

interface StoreContext {
  currencies: IFetchCurrencies;
  shippingDestinations: IFetchShippingDestinations;
  shippingCountry: string;
  shippingRates: IFetchShippingRates;
  storeShippingCountry(shippingCountryIso: string): void;
  storeCurrencyCode(currencyCode: string): void;
  currencyCode: string;
}

export const [StoreContextProvider, useStoreContext] =
  createContext<StoreContext>();
