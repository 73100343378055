import { Document } from '@contentful/rich-text-types';
import { create } from 'zustand';

interface IBasketDrawerStore {
  isOpen: boolean;
  handleOpen(): void;
  handleClose(): void;
}

export const useBasketDrawerStore = create<IBasketDrawerStore>((set) => ({
  isOpen: false,
  handleOpen: () => set((state) => ({ isOpen: true })),
  handleClose: () => set((state) => ({ isOpen: false })),
}));

export const useShippingReturnsDrawerStore = create<IBasketDrawerStore>(
  (set) => ({
    isOpen: false,
    handleOpen: () => set((state) => ({ isOpen: true })),
    handleClose: () => set((state) => ({ isOpen: false })),
  })
);

export const useSizeGuideDrawerStore = create<IBasketDrawerStore>((set) => ({
  isOpen: false,
  handleOpen: () => set((state) => ({ isOpen: true })),
  handleClose: () => set((state) => ({ isOpen: false })),
}));

export const useLocaleDrawer = create<IBasketDrawerStore>((set) => ({
  isOpen: false,
  handleOpen: () => set((state) => ({ isOpen: true })),
  handleClose: () => set((state) => ({ isOpen: false })),
}));

export const useWarrantyDrawerStore = create<IBasketDrawerStore>((set) => ({
  isOpen: false,
  handleOpen: () => set((state) => ({ isOpen: true })),
  handleClose: () => set((state) => ({ isOpen: false })),
}));

interface IGlobalContentStore {
  warranty: Document | undefined;
  handleSetWarranty(content: Document | undefined): void;
}

export const useGlobalContent = create<IGlobalContentStore>((set) => ({
  warranty: undefined,
  handleSetWarranty: (content) => set((state) => ({ warranty: content })),
}));
