import {
  BASKET_KEY,
  IS_SERVER,
} from '@/src/packages/components/contexts/store.context';
import { Queries, client } from '@/src/packages/server/clients/api';
import { createContext } from '@bitmap/utils/utils/createContext';
import { ReactNode, useEffect, useState } from 'react';
import {
  IAddLineItemsRequestDto,
  IFetchCheckout,
  ISetCheckoutDeliveryAddress,
  ISetCheckoutDeliveryAddressCountry,
} from '@bitmap/contracts';
import { Checkout, CheckoutLineItem, ShippingService } from '@prisma/client';
import { useBasketDrawerStore } from '@/src/packages/hooks/use-basket-drawer';
import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_NAMES,
  trackEvent,
} from '@/src/packages/server/clients/analytics';
import { useRouter } from 'next/navigation';
import { getRoute } from '@/src/packages/utils/routes';
import { paths } from '@/src/packages/routes';
import { useStoreContext } from '@/src/packages/components/contexts/store-context-provider.context';
import { differenceInDays, parseISO } from 'date-fns';
import { pathParams } from '@/src/packages/routes/pathParams.enum';
import { notifyBugReporter } from '@/src/packages/server/clients/bugReporter';
import { usePostHog } from 'posthog-js/react';

type IBasketContext = {
  basket: IFetchCheckout | null;
  setCurrency(currencyIso: string): void;
  setDeliveryAddress(values: ISetCheckoutDeliveryAddress): void;
  setDeliveryAddressCountry(values: ISetCheckoutDeliveryAddressCountry): void;
  setShippingService(shippingServiceId: ShippingService['id']): void;
  addItem(skus: IAddLineItemsRequestDto): void;
  removeItem({
    lineItemId,
    quantity,
  }: {
    lineItemId: CheckoutLineItem['id'];
    quantity: number;
  }): void;
  completeCheckout(): void;
  deleteBasket(): void;
  applyDiscount(code: string): void;
  removeDiscount(): void;
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatingCurrency: boolean;
  isUpdatingDeliveryCountry: boolean;
  isEmpty: boolean;
};

export const [BasketContext, useBasket] = createContext<IBasketContext>();

type StoredBasket = {
  basketId: string;
};
export const getBasketIdFromStorage = () => {
  const storedBasket = localStorage.getItem(BASKET_KEY) || '{}';

  try {
    const { basketId } = JSON.parse(storedBasket) as StoredBasket;

    return basketId;
  } catch (e) {
    return null;
  }
};

const isBasketExpired = (checkout: IFetchCheckout) => {
  const { createdAt } = checkout;

  const daysAgo = differenceInDays(new Date(), parseISO(createdAt));

  return daysAgo > 2 ? true : false;
};

const isCheckoutComplete = (checkout: IFetchCheckout) => {
  const { completedAt } = checkout;

  return completedAt;
};

export const BasketContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    storeCurrencyCode,
    currencyCode,
    shippingCountry,
    storeShippingCountry,
  } = useStoreContext();

  const [basketId, setBasketId] = useState<string | null>(null);

  const [basket, setBasket] = useState<IFetchCheckout | null>(null);

  const { handleOpen } = useBasketDrawerStore((state) => state);

  const { sessionManager } = usePostHog();

  const { data, isLoading, error } = client.checkout.getCheckout.useQuery(
    [Queries.basket],
    {
      params: { checkoutUUID: basketId || '' },
    },
    {
      enabled: !!basketId,
    },
  );

  useEffect(() => {
    if (!IS_SERVER && currencyCode && shippingCountry) {
      const storedBasket = localStorage.getItem(BASKET_KEY);

      if (storedBasket) {
        const { basketId } = JSON.parse(storedBasket) as StoredBasket;

        setBasketId(basketId);
      } else {
        createCheckout();
      }
    }
  }, [currencyCode, shippingCountry]);

  // Delete the basket if for example the user has an invalid id
  useEffect(() => {
    if (error) deleteBasket();
  }, [error]);

  useEffect(() => {
    if (data && data.body) {
      if (!isBasketExpired(data?.body) && !isCheckoutComplete(data.body)) {
        setBasket(data?.body);
      } else {
        console.log('basket expired or complete, recreating');
        deleteBasket();
      }
    }
  }, [data]);

  const setCurrency = async (currencyIso: string) => {
    if (basketId) {
      updateCurrencyMutation({
        params: { checkoutUUID: basketId },
        body: { currencyIso },
      });
    } else {
      storeCurrencyCode(currencyIso);
    }
  };

  const { mutate: completeCheckoutMutation, isLoading: isCompleting } =
    client.checkout.complete.useMutation({
      onSuccess: ({ body }) => {
        setBasket(body);

        if (body?.uuid) {
          router.push(
            getRoute({
              route: paths.CHECKOUT_CONFIRMATION,
              params: { [pathParams.CHECKOUT]: body.uuid },
            }),
          );
        }
      },
      onError: () => {
        notifyBugReporter('Failed to complete checkout');
      },
    });

  const {
    mutate: setShippingMethodMutation,
    isLoading: isSettingShippingMethod,
  } = client.checkout.setShippingMethod.useMutation({
    onSuccess: ({ body }) => {
      setBasket(body);
    },
    onError: () => {
      notifyBugReporter('Failed to set shipping method');
    },
  });

  const { mutate: updateCurrencyMutation, isLoading: isUpdatingCurrency } =
    client.checkout.updateCurrency.useMutation({
      onSuccess: ({ body }) => {
        setBasket(body);

        if (body?.currencyIso) storeCurrencyCode(body?.currencyIso);
      },
      onError: (error) => {
        notifyBugReporter(error);
      },
    });

  const { mutate: removeLineItemMutation, isLoading: isRemovingLineItem } =
    client.checkout.removeLineItem.useMutation({
      onSuccess: ({ body }) => {
        setBasket(body);
      },
      onError: () => {
        notifyBugReporter('Failed to remove line item');
      },
    });

  const { mutate: applyDiscountMutation, isLoading: isApplyingDiscount } =
    client.checkout.applyDiscount.useMutation({
      onSuccess: ({ body }) => {
        setBasket(body);
      },
      onError: () => {
        alert('Discount code is not valid.');
      },
    });

  const removeItem = ({
    lineItemId,
    quantity,
  }: {
    lineItemId: CheckoutLineItem['id'];
    quantity: number;
  }) => {
    if (basketId)
      removeLineItemMutation({
        body: { quantity },
        params: { checkoutUUID: basketId, lineItemId: lineItemId.toString() },
      });
  };

  const { mutate: createCheckoutMutation, isLoading: isCreating } =
    client.checkout.create.useMutation({
      onSuccess: ({ body }) => {
        setBasket(body);
        setBasketId(body.uuid);
        storeBasket(body.uuid);
      },
      onError: ({ body }) => {
        notifyBugReporter(`Failed to create checkout: ${JSON.stringify(body)}`);
      },
    });

  const { mutate: removeDiscountMutation } =
    client.checkout.removeDiscount.useMutation({
      onSuccess({ body }) {
        setBasket(body);
      },
      onError: () => {
        notifyBugReporter('Failed to remove discount');
      },
    });

  const { mutate: addLineItemMutation, isLoading: isAdding } =
    client.checkout.addLineItem.useMutation({
      onSuccess: ({ body }) => {
        setBasket(body);
        handleOpen();
      },
      onError: (body) => {
        notifyBugReporter('Failed to add line item');
      },
    });

  const setShippingService = (shippingServiceId: ShippingService['id']) => {
    if (basketId)
      setShippingMethodMutation({
        params: { checkoutUUID: basketId },
        body: { shippingServiceId },
      });
  };

  const removeDiscount = () => {
    if (basketId)
      removeDiscountMutation({ params: { checkoutUUID: basketId } });
  };

  const createCheckout = () => {
    if (currencyCode && shippingCountry) {
      createCheckoutMutation({
        body: {
          currencyIso: currencyCode,
          shippingDestinationIso: shippingCountry,
          referrer: document.referrer,
          posthogSessionId:
            sessionManager?.checkAndGetSessionAndWindowId().sessionId,
        },
      });
    }
  };

  const applyDiscount = (code: string) => {
    if (basketId) {
      applyDiscountMutation({
        body: { discountCode: code },
        params: { checkoutUUID: basketId },
      });

      trackEvent({
        name: ANALYTICS_EVENT_NAMES.CHECKOUT_APPLY_DISCOUNT_CODE,
        category: ANALYTICS_EVENT_CATEGORIES.CHECKOUT,
      });
    }
  };

  const addItem = (skus: IAddLineItemsRequestDto) => {
    if (basketId) {
      addLineItemMutation({
        body: skus,
        params: { checkoutUUID: basketId },
      });
    }
  };

  const completeCheckout = async () => {
    if (basketId) {
      await completeCheckoutMutation({ params: { checkoutUUID: basketId } });
    }
  };

  const router = useRouter();

  const {
    mutate: setDeliveryAddressMutation,
    isLoading: isUpdatingDeliveryAddress,
  } = client.checkout.setDeliveryAddress.useMutation({
    onSuccess: ({ body }) => {
      if (basketId && body?.address?.countryIsoCode) {
        setBasket(body);

        storeShippingCountry(body?.address?.countryIsoCode);

        router.push(
          getRoute({
            route: paths.CHECKOUT_SHIPPING_METHODS,
            params: { [pathParams.CHECKOUT]: basketId },
          }),
        );
      }
    },
  });

  const {
    mutate: setDeliveryAddressCountryMutation,
    isLoading: isUpdatingDeliveryCountry,
  } = client.checkout.setDeliveryCountry.useMutation({
    onSuccess: ({ body }) => {
      setBasket(body);

      if (body?.address?.countryIsoCode)
        storeShippingCountry(body?.address?.countryIsoCode);
    },
    onError: () => {
      notifyBugReporter('Failed to set delivery country');
    },
  });

  const setDeliveryAddress = (values: ISetCheckoutDeliveryAddress) => {
    if (basketId)
      setDeliveryAddressMutation({
        body: values,
        params: { checkoutUUID: basketId },
      });
  };

  const setDeliveryAddressCountry = (
    values: ISetCheckoutDeliveryAddressCountry,
  ) => {
    if (basketId)
      setDeliveryAddressCountryMutation({
        body: values,
        params: { checkoutUUID: basketId },
      });
  };

  const storeBasket = (id: Checkout['uuid']) => {
    const payload: StoredBasket = {
      basketId: id,
    };

    if (!IS_SERVER) localStorage.setItem(BASKET_KEY, JSON.stringify(payload));
  };

  const deleteBasket = () => {
    if (!IS_SERVER) {
      localStorage.removeItem(BASKET_KEY);
      setBasketId(null);
      setBasket(null);
      createCheckout();
    }
  };

  const isEmpty = !basket || basket.lineItems?.length === 0;

  return (
    <BasketContext
      value={{
        basket,
        isLoading,
        isEmpty,
        addItem,
        applyDiscount,
        removeDiscount,
        removeItem,
        completeCheckout,
        setCurrency,
        setShippingService,
        setDeliveryAddress,
        setDeliveryAddressCountry,
        deleteBasket,
        isUpdatingCurrency,
        isUpdatingDeliveryCountry,
        isUpdating:
          isAdding ||
          isRemovingLineItem ||
          isCreating ||
          isApplyingDiscount ||
          isSettingShippingMethod ||
          isUpdatingDeliveryAddress ||
          isUpdatingDeliveryCountry ||
          isCompleting,
      }}
    >
      {children}
    </BasketContext>
  );
};
