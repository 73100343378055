import { env } from '@/env.mjs';

export const StoreConfig = {
  emails: {
    cs_email: 'contact@albert-arthur.com',
  },
  store_id: parseInt(env.NEXT_PUBLIC_STORE_ID),
  default_store_country_iso: 'GB',
  default_currency_iso: 'GBP',
  facebook_tracking_pixel_id: '211208228409765',
  site_name: 'Albert & Arthur England',
  site_name_short: 'Albert & Arthur',
  registered_company_address: '64 Nile Street, N1 7SR, United Kingdom',
  company_number: '14300293',
  site_title: 'Beautiful and functional items for your everyday',
  og_description:
    'Based in Norfolk, England, we started Albert & Arthur to create beautiful items for your everyday.',
  instagram_url: 'https://www.instagram.com/albertandarthurgb',
  pinterest_url: 'https://www.pinterest.co.uk/albertandarthurgb/',
  linkedin_url: 'https://linkedin.com/company/albertarthur',
  contentful_page_ids: {
    home: '3m9xZ66ij07qCND8Bpxtc0',
    modern_slavery: '2T4jhZ6r08ge0EIsFwNGv2',
    accessibility: '2bGYtRwFjMUi28790E18mp',
    journal_index: '5qzGS09UCtXzhpr86dMEAP',
    contact: '16dAeu5VUBAJqs6MCfQwmX',
    about: '4MWEHGPytKtFdrQi3mQhYU',
    faqs: '2pgu6D58EwHblKa2xuM06J',
    materials: 'tKHKWO0H1LC9X9NFuYWhv',
    makers: '7FDKOBkq0BJjFC1lUWdURC',
    lifetime_warranty: '7M8V57t9M8wrMsXoScHJNX',
    shipping_returns: '1P7IpYZMpoW7SavxdDyDIq',
    influencers: '159FrcgAKGQh9Cy0oK4wyu',
    privacy: 'DrKqmQcfO9zgsJbeYGU3f',
    ranges_index: '7uyqy7XcK9nHZQr4asttX3',
    product_care_index: 'aB0nwJYjM2oTFR6CiO09v',
    press: '4eDDY6fpBZWeUlq6QieYdz',
    terms: '1oORr0spKw4OoDQoW2am0U',
    reviews: '7dnK99P51R2IjOkfP5hg2g',
    size_guides: '65UXHisOGkhLPXAqHSpXEi',
  },
};
