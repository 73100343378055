import { env } from '@/env.mjs';
import { contracts } from '@bitmap/contracts';
import { initQueryClient } from '@ts-rest/react-query';

export enum Queries {
  'basket' = 'basket',
  'currencies' = 'currencies',
  'shipping_rates' = 'shipping_rates',
  'shipping_destinations' = 'shipping_destinations',
}

export const ApiKeyHeader = 'api_key';

export const client = initQueryClient(contracts, {
  baseUrl: '/api',
  baseHeaders: {
    [ApiKeyHeader]: env.NEXT_PUBLIC_STORE_ID,
  },
});
