'use client';
import { ReactNode, useEffect, useState } from 'react';
import { StoreConfig } from '@/src/store.config';
import { StoreContextProvider } from '@/src/packages/components/contexts/store-context-provider.context';
import { IFetchCurrencies, IFetchShippingRates } from '@bitmap/contracts';
import { IFetchShippingDestinations } from '@bitmap/contracts';

type StoredCurrency = {
  currencyIso: string;
};

type StoredCountry = {
  countryIso: string;
};

export const IS_SERVER = typeof window === 'undefined';

export const BASKET_KEY = 'basket_key';

export const CURRENCY_KEY = 'currency_key';

export const COUNTRY_KEY = 'country_key';

export const StoreProvider = ({
  children,
  currencies,
  shippingDestinations,
  shippingRates,
}: {
  children: ReactNode;
  currencies: IFetchCurrencies;
  shippingDestinations: IFetchShippingDestinations;
  shippingRates: IFetchShippingRates;
}) => {
  const [currencyCode, setCurrencyCode] = useState<string>('GBP');

  const [shippingCountry, setShippingCountry] = useState<string>('GB');

  const storeCurrencyCode = (currencyIso: string) => {
    if (!IS_SERVER) {
      const value: StoredCurrency = {
        currencyIso,
      };

      localStorage.setItem(CURRENCY_KEY, JSON.stringify(value));

      setCurrencyCode(currencyIso);
    }
  };

  const storeShippingCountry = (countryIso: string) => {
    if (!IS_SERVER) {
      const value: StoredCountry = {
        countryIso,
      };

      localStorage.setItem(COUNTRY_KEY, JSON.stringify(value));

      setShippingCountry(countryIso);
    }
  };

  useEffect(() => {
    if (!IS_SERVER) {
      const storedCurrency = localStorage.getItem(CURRENCY_KEY);

      if (storedCurrency) {
        const { currencyIso } = JSON.parse(storedCurrency) as StoredCurrency;

        setCurrencyCode(currencyIso);
      } else {
        storeCurrencyCode(StoreConfig.default_currency_iso);
      }
    }
  }, []);

  useEffect(() => {
    if (!IS_SERVER) {
      const storedCountry = localStorage.getItem(COUNTRY_KEY);

      if (storedCountry) {
        const { countryIso } = JSON.parse(storedCountry) as StoredCountry;

        setShippingCountry(countryIso);
      } else {
        storeShippingCountry('GB');
      }
    }
  }, []);

  return (
    <StoreContextProvider
      value={{
        currencyCode,
        storeCurrencyCode,
        storeShippingCountry,
        shippingCountry,
        currencies,
        shippingDestinations,
        shippingRates,
      }}
    >
      {children}
    </StoreContextProvider>
  );
};
